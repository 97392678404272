export type Sponsor = {
	id: string
	header: string
	subHeader: string
	companyName: string
	sponsorName: string
	website: string
	phone: string
	email: string
	content: string
	footer: string
	imgSrc: string
	description: string
}

export const SPONSORS = [
	{
		id: 'sagility',
		header: 'Anand Natampalli',
		subHeader: 'Tech Enabled Healthcare BPM',
		companyName: 'Sagility Health',
		sponsorName: 'Anand Natampalli',
		website: 'https://www.sagilityhealth.com',
		phone: '+1-201-286-7717',
		email: 'anandmn@sagilityhealth.com',
		content: 'Content',
		footer: 'Footer',
		imgSrc: '/img/sponsors/anand_natampalli.png',
		features: [],
		description:
			'Sagility is a U.S.-based, tech-enabled healthcare business process management company that supports payers, providers, and their partners to deliver best-in-class operations, enhance the member and provider experience, improve the quality of care and promote health equity all while delivering cost effective healthcare financial and clinical outcomes.',
	},
	{
		id: 'rci',
		header: 'Ankeit Ghai',
		subHeader: 'Real Estate Property Mgmt.',
		companyName: 'RCI Realty',
		sponsorName: 'Ankeit Ghai',
		website: 'https://www.rcirealtyllc.com',
		phone: '+1-847-404-5699',
		email: 'ankeit@rcirealtyllc.com',
		content: 'Content',
		footer: 'Footer',
		imgSrc: '/img/sponsors/ankeit_ghai.png',
		features: [],
		description:
			'RCI Property Management is a full-service property management brokerage with a proven ability to create and operate thriving rental apartment communities. Our team provides the expertise of management needed to effectively lease multi-family, condo and single-family properties in Illinois.\n We enhance an asset’s long-term value through quality preventative maintenance, strategic capital improvements, exceptional customer service, and thoughtful management of every dollar to your bottom line.',
	},
	{
		id: 'willow',
		header: 'Rahul Bhatia',
		subHeader: 'Hedge Fund Investment',
		companyName: 'Willow Investment Management',
		sponsorName: 'Rahul Bhatia',
		website: 'https://www.willowinvest.com',
		phone: '+1-214-668-9666',
		email: 'investors@willowinvest.com',
		content: 'Content',
		footer: 'Footer',
		imgSrc: '/img/sponsors/rahul_bhatia.png',
		features: [],
		description:
			'India focused fund for US based investors.\nDesigned for accredited US citizens and NRIs who want to participate in India’s long term growth story in a convenient, transparent manner.\n11+ year track record of significantly outperforming India focused ETFs and low-cost mutual funds.\nActively managed by portfolio managers with more than 30 years of India investing experience.',
	},
	{
		id: 'chawla',
		header: 'Roger Chawla',
		subHeader: 'CPA / Tax Consultant',
		companyName: 'Chawla & Associates',
		sponsorName: 'Roger Chawla',
		website: 'https://www.chawlacpa.com',
		phone: '+1-630-922-8600',
		email: 'roger@chawlacpa.com',
		content: 'Content',
		footer: 'Footer',
		imgSrc: '/img/sponsors/chawla.png',
		description: null,
		features: [
			'A boutique CPA firm located in Naperville, IL. At Chawla & Associates, Inc., we believe in the value of relationships',
			'We view every client relationship like a partnership, and truly believe that our success is a result of your success',
			'Income Tax Preparation for all types of businesses, & individuals',
			'IRS and State Audit Representation',
			'Payroll Reporting',
			'QuickBooks and Peachtree software setup, support and training',
			'Business startup services, including choice of entity, structure, and capital formation',
			'Monthly bookkeeping and accounting',
			'Financial statements - making sure your financial records are timely and accurate',
			'Assistance with the IRS issues including installment agreements and offers in compromise',
			'FBAR (Foreign Bank Accounting Reporting) and amnesty OVDI (Overseas Volunteer Disclosure Initiative) procedure representation',
		],
	},
	{
		id: 'swg',
		header: 'Sanjay Wagle',
		subHeader: 'Wealth Management',
		companyName: 'Strategic Wealth Group',
		sponsorName: 'Sanjay Wagle',
		website: 'https://www.swgadvisors.com',
		phone: '+1-630-469-0100',
		email: 'swagle@swgadvisors.com',
		content: 'Content',
		footer: 'Footer',
		imgSrc: '/img/sponsors/sanjay_wagle.jpg',
		description: null,
		features: [
			'Independent boutique Financial Planning and Wealth Management Firm with a focus on good folks who care about hands-on, personal attention.',
			'Individualized planning strategies for tax efficient wealth accumulation, decumulation and preservation.',
			'Tax efficient, actively managed portfolios that blend fundamental research and technical analysis.',
			'White glove experience that builds deep and meaningful client partnerships.',
			'If you fail to plan, you are planning to fail - Benjamin Franklin',
		],
	},
	{
		id: 'abs',
		header: 'Snehal Patel',
		subHeader: 'Mortgage Agent',
		companyName: 'ABS Funding',
		sponsorName: 'Snehal Patel',
		website: 'https://www.absmtg.com',
		phone: '+1-847-942-2393',
		email: 'spatel@absmtg.com',
		content: 'Content',
		footer: 'Footer',
		imgSrc: '/img/sponsors/snehal.png',
		features: [],
		description:
			'Strong reputation as an outstanding mortgage brokerage firm Serving the lending needs of real estate professionals, builders, and individual home buyers throughout Illinois. Full-service mortgage brokerage with an experienced staff offering expertise in every area of mortgage lending from purchase to refinance to construction lending. Access to a full range of mortgage products dedicated to finding the right loan-with the best rates, terms and costs-to meet vour unique needs.',
	},
	{
		id: 'omega',
		header: 'Sumit Sachdeva',
		subHeader: 'Healthcare RCM Company',
		companyName: 'Omega Healthcare',
		sponsorName: 'Sumit Sachdeva',
		website: 'https://www.omegahms.com',
		phone: '+1-630-889-9629',
		email: 'sumit.sachdeva@omegahms.com',
		content: 'Content',
		footer: 'Footer',
		imgSrc: '/img/sponsors/sumit_sachdeva.png',
		features: [],
		description:
			'Omega Healthcare is a trusted partner that helps improve financial outcomes through technology and clinically enabled transformational solutions for healthcare and life sciences organizations.\nOmega Healthcare’s flexible portfolio of platform-based services is tailored to the unique needs of its clients. Solutions encompass point solutions and fully managed services and help increase revenues, decrease costs, and improve the overall patient-provider-payer experience.',
	},
]
